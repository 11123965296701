<template>
  <v-container class="fill-height pa-0" fluid dark>
    <v-card
      class="transparent elevation-0 pa-0 mx-auto"
      :width="$vuetify.breakpoint.smAndDown ? 400 : 600"
    >
      <v-img :src="require('@/assets/logo_toro_white.svg')" height="183" contain />
      <v-card-text class="text-center">
        <span class="subtitle-1"
          >Accessing<v-progress-circular
            indeterminate
            size="15"
            width="1"
            class="ml-3"
          ></v-progress-circular>
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { onMounted, inject } from '@vue/composition-api';
import router from '../store/Router';
import HttpClientV2 from '../store/HttpClientV2';

const USER_KEY = 'USER_KEY';
const TOKEN_KEY = 'TOKEN_KEY';

export default {
  name: 'impersonation',
  setup() {
    const application = inject('Application');
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
    onMounted(async () => {
      const result = await HttpClientV2.callFunctionV2('POST', 'impersonation/verify', {
        token: router.currentRoute.query.token,
      });
      localStorage.setItem(TOKEN_KEY, result.data.token);
      localStorage.setItem(USER_KEY, JSON.stringify(result.data.user));
      application.actions.impersonate();
    });
    return {};
  },
};
</script>
